export default {
  common: {
    switchLang: '切换语言成功',
    submit: '提交',
    exampleFile: '案例参考',
    processingData: '数据处理中，请稍候...',
    preparingData: '数据准备中，请稍候...',
    indirecting: '跳转中，请稍候...',
    pleaseConfirm: '请确认',
    applySucc: '申请成功',
    saveSucc: '保存成功',
    deleteSucc: '删除成功',
    pleaseSelect: '请选择',
    new: '新建',
    image: '图片',
    name: '名称',
    detail: '详情',
    type: '类型',
    search: '搜索',
    createAt: '创建时间',
    download: '下载',
    delete: '删除',
    loading: '加载中',
    downloading: '下载中',
    noMoreData: '暂无更多数据',
    abort: '中止',
    ok: '确定',
    cancel: '取消',
    downloadAll: '下载全部',
    generate: '生成',
    regenerate: '重新生成',
    prompt: '提示',
    action: '运行',
    email: '邮箱',
    username: '用户名',
    password: '密码',
    import: '导入',
    export: '导出',
    brand: '品牌',
    guide: '指南',
    report: '报告',
    summaryReport: 'Summary报告',
    reportManual: '报告手册',
    keyword: '关键字',
    signin: '登录',
    signout: '退出',
    item: "款式",
    donotshow: '不再显示',
    product: '商品',
    selectAll: '全选',
    selectReverse: '反选',
    week: '周',
    month: '月',
    all: '全部',
    distinguish: '区分',
    notDistinguish: '不区分',
    noPermit: '您没有权限！',
    proportion: '比重',
    color: '颜色',
  },
  route: {
    home: '首页',
    service: '服务',
    document: '文档',
    about: '关于',
    manual: '手册',
    tagger: '智能识图技术',
    analysis: '趋势分析',
    imageDownload: '图片下载',
    aeye: '睿目',
    my: '我的',
    profile: '用户详情',
    order: '权限',
    new: '新建',
    detail: '详细',
    generate: '生成报告',
    dataCenter: '数据中心',
    weeklyBoard: '周报',
    salesData: '销售数据',
    stockData: '库存数据',
    imageData: '图片数据',
    visual: '视觉',
    streetFashion: '时尚街拍',
    streetTrend: '街头趋势报告',
    windowFashion: '商圈陈列',
    windowTrend: '商圈趋势报告',
    salesItem: '上架商品概况',
    salesTrend: '销售趋势',
    weeklySales: '周销售',
    styleSearch: '款式查询',
    newProduct: '本周新款',
    risingProduct: '上升最快商品',
    productDetail: '商品详情',
    channel: "销售渠道类型",
    place: '使用场景',
    concept: '风格',
    specificItem: '小品类查询',
    trendMap: '趋势数据表',
    trendMatrix: '趋势数据图',
    imageTrendMap: '图片趋势数据表',
    reportConsulting: '报告与咨询',
    consultingReport: '咨询报告',
    globalTrendReport: '国际趋势报告',
    globalTrend: 'Global Trend',
    nlpAnalysis: 'NLP分析',
    aiStyleScene: 'AI “风格现场”',
    creator: '创作者',
    myLookbook: '我的“风格现场”',
    searchWords: '单词查询',
    productName: '商品名',
    popularElements: '流行元素',
    priceReport: '价格分析报告',
    itemReport: '款式分析报告',
    colorReport: '颜色分析报告',
    textileReport: '面料分析报告',
    best20Images: '前二十商品照片',
    best20Report: '前二十商品报告',
    fashionAIDesigner: '时尚AI“设计师”',
    aiDesign: "AI设计",
    fashionSketches: "时尚素描",
    modelAIMorph: '模型变形',
    modelReplace: '更换模型',
    modelLibrary: '模型图库',
    fashionAIAD: 'Fashion AI "AD"',
    aiAD: 'AI "AD"',
    adLibrary: 'AD Library',
    fashionAIConcepter: '时尚AI“设计”',
    aiConcepter: 'AI“设计师”',
    conceptSketches: '设计草图',
    fashionAIDrawing: '时尚AI“绘图”',
    aiDrawing: 'AI“绘图”',
    drawingBook: '绘图本',
    productReviews: 'Product Reviews',
    sns: 'SNS',
    itemDashboard: 'Item Dashboard',
    trendDashboard: 'Trend Dashboard',
    itemCompare: 'Item 对比',
    trendCompare: 'Trend 对比',
    itemSearch: '款式查询',
    trendSearch: 'Trend 查询',
    itemPrediction: 'Item Prediction',
    trendPrediction: 'Trend Prediction',
  },
  verify: {
    applyList: '申请列表',
    approve: '批准',
    reject: '拒绝'
  },
  profile: {
    changePassword: '修改密码',
    originalPassword: '原密码',
    newPassword: '新密码',
    confirmPassword: '确认新密码'
  },
  order: {
    myServices: '我的服务',
    applyFreeTrail: '申请免费试用',
    service: '服务',
    level: '等级',
    status: '状态',
    expireAt: '到期时间',
    remainImageCount: '剩余图片数',
    selectService: '选择要申请的服务',
    noValidService: '您没有可用的服务，点击确定以检查服务列表！',
    applied: '已申请',
    approved: '已审批',
    rejected: '已驳回',
    basic: '基础',
    advanced: '进阶',
    premium: '高级'
  },
  signup: {
    title: '创建您的F&Plus账号',
    confirmPassword: '确认密码',
    phoneNo: '手机号',
    company: '公司',
    area: '地区',
    signup: '注册',
    loginInfo: '审批结果请通过邮箱确认。如信息错误，将退回您的申请。',
    emailRequired: '请输入邮箱',
    emailFormat: '请输入正确的邮箱格式',
    userNameRequired: '请输入用户名',
    passwordRequired: '请输入密码',
    confirmPasswordRequired: '请再次输入密码',
    phoneNoRequired: '请输入电话号码',
    companyRequired: '请输入公司',
    areaRequired: '请选择地区',
    userNameLength: '用户名长度在4到20个字符',
    passwordLength: '密码长度在6到32个字符',
    passwordNotMatch: '两次输入的密码不一致',
    succSignup: '注册成功',
    succSignupInfo: '请使用新账号登录',
    emailAlreadyExist: '邮箱已存在',
    usernameAlreadyExist: '用户名已存在',
  },
  signin: {
    title: '登录到F&Plus',
    emailUsername: '邮箱或用户名',
    forgot: '忘记密码？',
    signupGuide: '第一次访问F&Plus？',
    signupLink: '创建账号',
    emailRequired: '请输入正确的电子邮箱或用户名',
    passwordRequired: '密码不能少于4位',
    redirectTitle: '此账号在本站不可用',
    redirectMsg: '要使用此账号登录, 请前往{code}站',
    intl: '国际',
    cn: '中国',
    go: '前往'
  },
  about: {
    desc: '公司简介',
    manual: 'AI Tagger 用户手册',
    contact: '联系我们',
    descContent: 'F&PLUS是一家具有AI(人工智能)核心技术的公司，它可以通过ML来分辨图片并检测视频。 图片分类 Demo Service是指，将时装商品按照 ( 1.分类 2.款式 3.小品类 )等级进行划分，同时将其广泛应用于商品计划、生产、销售、销售数据采集等的核心源泉技术。',
    contactTitle: '钟女士 (市场开发部经理)',
    contactTel: '电话: 15800974241(WeChat同号)',
  },
  tagger: {
    desc: '简介',
    status: '状态',
    execAt: '开始时间',
    finishAt: '结束时间',
    imageCount: '图片数量',
    restart: '重启',
    deleteTask: '删除任务？',
    restartSucc: '重启成功',
    defaultCategory: '默认分类',
    imageSource: '图片源',
    imageFile: '图片文件',
    urlsInExcel: '图片URL',
    picsInExcel: '图片Excel',
    uploadImages: '上传图片',
    uploadExcel: '上传Excel',
    clickToUpload: '点击上传',
    succUpload: '成功上传{length}条数据',
    submitTask: '是否提交？',
    invalidImages: '无效图片',
    category: '分类',
    itemType: '款式',
    specificItem: '小品类',
    runningTagger: '智能识图技术运行中，请稍候...',
    finishImages: '已识别图片: {finishCount} / {totalCount}',
    waiting: '等待',
    running: '运行',
    done: '完成',
    abort: '中止',
    error: '出错'
  },
  analysis: {
    generateReport: '生成报告',
    period: '周期',
    date: '日期',
    compareDate: '对比日期',
    platform: '平台',
    deleteReport: '删除报告？',
    downloadAll: '下载全部',
    downloadImages: '正在下载图片: {finishCount} / {totalCount}',
    downloadProcess: '正在下载 : {finishCount} / {totalCount}',
    finishDownload: '下载完成！',
    generateSucc: '{type} 报告生成成功！',
    reportExist: '相同日期的报告已经存在！',
    reportRegenerate: '报告已存在，是否重新生成并覆盖之前的报告？',
    group: "品牌群",
    criterion: "评价基准",
    sales: "销售额",
    salesVolume: "销量",
    styles: "款式数",
    graph: "图表",
    fixedBar: "堆积柱形图",
    percentageBar: "百分比柱形图"
  },
  data: {
    sumStyle: "商品数（款式）",
    sumStock: "库存数",
    colorAvg: "各款式颜色平均数",
    sizeAvg: "各款式尺寸平均数",
    sumMonthSale: "月销量",
    saleStockPercentage: "销售数量库存占比",
    newProducts: "本周新商品数",
    repeatProducts: "再上架商品数",
    currentYearProducts: "本年商品数",
    previousYearProducts: "往年商品数",
  },
  visual: {
    when: "时间",
    place: "地点",
    sex: "性别",
    season: "季节"
  },
  salesItem: {
    item: "款式（中品类）",
    styleAmount: "款式数量",
    saleQty: "销售数量",
    minListPrice: "吊牌最低价",
    maxListPrice: "吊牌最高价",
    middleListPrice: "吊牌中间价",
    minSalePrice: "实际销售最低价",
    maxSalePrice: "实际销售最高价",
    middleSalePrice: "实际销售中间价",
    discountRate: "折扣率"
  },
  risingProduct: {
    item: "款式（中品类）",
    weekSaleQty: "本周为止月销量（30天）",
    lastWeekSaleQty: "上周为止月销量（30天）",
    increaseSaleQty: "月销量差",
    onSaleTime: "上架年度",
    listPrice: "吊牌价",
    salePrice: "实际销售价"
  },
  newProduct: {
    item: "款式（中品类）",
    weekSaleQty: "本周为止月销量（30天）",
    season: "季度",
    colorCount: "颜色",
    sizeCount: "尺寸",
    listPrice: "吊牌价",
    salePrice: "实际销售价"
  },
  productDetail: {
    detail: "商品详情",
    item: "款式（中品类）",
    value: "种类",
    newValue: "更新种类",
    originalValue: "原种类",
    saleQty: "本周为止月销量（30天）",
    listPrice: "吊牌价",
    salePrice: "实际销售价",
    detailSearch: "详细查询"
  },
  analysisDetail: {
    pattern: "Pattern",
    fG: "FG",
    collar: "领型",
    waistType: "腰型",
    pantLength: "裤长",
    pantsFit: "款式版型",
    channel: "销售渠道类型",
    place: "使用场景",
    concept: "风格"
  },
  productInfo: {
    style: "ID/货号",
    baseDate: "最新日期",
    logo: "Logo",
    yearSeason: "上市时间",
    color: "颜色",
    size: "尺寸",
    textile: "材质",
    patternFG: "图案",
    collar: "领型",
    waistStyle: "腰型",
    pantsLength: "裤长",
    pantsFit: "款式版型",
    channel: "销售渠道类型",
    place: "适用场景",
    concept: "风格",
    trendPoint: "流行元素",
    overallSalesRanking: "销售额排名\n（品牌内）",
    inItemSalesRanking: "销售额排名\n（款式内）",
    salesPositionAll: "销售额占比\n（品牌内）",
    salesPositionItem: "销售额占比\n（款式内）",
    sales: "销售额",
    salesVolume: "销量",
    realPrice: "促销价"
  },
  nlpAnalysis: {
    basic: "基本",
    compare: "对比",
    trend: "趋势",
    businessType: "服装种类",
    date: '日期',
    compareDate: "对比日期",
    startDate: "开始日期",
    endDate: "结束日期",
    count: "选择日期数",
    item: "款式",
    length: "词长度",
    category: "分类",
  },
  imageTrendMap: {
    specificItem: '小品类',
    volume: '销售额',
    trend: '趋势增减',
    quadrant: '分面',
    advice: '建议',
    overall: '整体',
    detail: '详情',
  },
  report: {
    target: '维度',
    group: '品牌群',
    product: '商品',
    fashion: '服装',
    etc: '其他',
    season: '季度',
    currentYear: '本年',
    previousYear: '往年',
    category: '大品类',
    item: '款式',
    midPrice: '中间价',
    realMidPrice: '实际销售中间价',
    tagMidPrice: '吊牌中间价',
    discount: '折扣率',
    priceRange: '价格范围',
    max: '最高',
    midt: '中间价（吊牌价）',
    midr: '中间价（实际售价）',
    min: '最低',
    sales: "销售额",
    style: "款式",
    color: "颜色",
    sku: "SKU",
    stock: "库存",
    criterion: "评价基准",
    textile: "材质",
  },
  globalTrend: {
    year: "年",
    season: "季节",
    sex: '性别',
    category: '大分类',
    item: '款式',
    difference: '区别',
    proportionInRecency: '最新值 比重',
    specificItem: '小品类',
    trendPoint: '流行元素',
    noticeWarning: '注意 ：时装秀提供的照片仅供内部参考。严禁下载、转发或传播。 我们的服务给您带来的设计灵感及信息，希望仅用于您的创作设计及分析。'
  },
  gpt: {
    noticeInfo: "图像为使用GPT模型的虚拟图像。作为参考图像，仅可作为商品策划、设计等的参考图像，不得转发、共享或用于其他用途。",
    noticeWarning: "注意：人工智能模型会努力防止带有性羞耻或令人厌恶等类似图像的出现，但若发现，请使用系统提供的【举报】按钮。",
    noticeWarningConcept: `注意：1.人工智能模型会努力防止带有性羞耻或令人厌恶等类似图像的出现，但若发现，请使用系统提供的【举报】按钮。
2.参考品牌使用指南：参考品牌仅作为参考，旨在帮助用户根据自身需求创作设计出新的图片。 并非直接复制模仿参考品牌，而是将其与用户自身的设计理念及需求相结合，创造设计出新的图片。`,
    modelNotice: "【注意】\n*基础参照品牌：{0}\n*顾客所需定制参照品牌，可通过协商报价后单独开发。",
    noticeInfoAD: "模特将使用生成式人工智能穿戴实际产品。每次仅能应用一件单品。 如果需要为模特穿戴两件单品，请分别重复生成过程。",
    report: "举报",
    favorite: "收藏",
    comfirmReport: "确认举报图片？",
    sex: '性别',
    women: '女性',
    man: '男性',
    child: '儿童',
    childGirl: '女童',
    childBoy: '男童',
    style: '风格',
    place: '地点',
    trendPoint: "流行元素",
    notice: '注意事项',
    desc: '描述',
    status: "状态",
    execAt: '开始时间',
    finishAt: '结束时间',
    imageCount: '图片数量',
    restart: '重新开始',
    when: '生成时间',
    runningInfo: 'GPT Creator 运行中，请稍候...',
    item: '款式',
    specificItem: '小品类',
    fit: '合身',
    collar: '衣领',
    sleeve: '袖子',
    front: '前面',
    subsidiaryMaterials: '辅助材料',
    features: '特征',
    fabric: '面料',
    pattern: '图案',
    length: '长度',
    pose: '姿势',
    age: '年龄',
    face: '人脸',
    season: '季节',
    targetFace: '目标人脸',
    sourceImage: '原始图像',
    userAI: "用户 AI",
    prompt: '提示词',
    mask: 'Mask',
    upload: '上传',
    selfDrawing: '自行“绘图”',
    select: '选择',
    option: '选项',
    selfPrompt: '直接输入',
    color: '颜色',
    modelImage: '模特图片',
    productImage: '商品图片',
    maskImage: '遮罩图片'
  }
}
